/// <reference path="../typings/index.d.ts" />
function initMap() {
  Maps.setMap('map-canvas');
}

class TrackerAnalytics {
  private _categoryGeneral: string;

  constructor(campaign?: string) {
    this._categoryGeneral = campaign;
    this.parseLinks();
  }

  parseLinks(): void {
    $('[data-event]').on('click', (evt, noEvent = false) => {
      if (!$(evt.currentTarget).data('event-manual') && !noEvent) {
        this.sendEvent($(evt.currentTarget));
      }
    });
  }

  sendEvent(elm: JQuery): void {
    var category: string;
    var action: string;
    var label: string;
    if (elm.data('event') == 'self') {
      label = elm.text();
    } else if (elm.data('event') == 'selector') {
      label = $(elm.data('event-selector'), elm).text();
    } else {
      label = elm.data('event');
    }
    if (elm.data('event-category')) {
      category = elm.data('event-category');
    } else if (elm.parent().data('event-category')) {
      category = elm.parent().data('event-category');
    } else if ($('body').data('event-category')) {
      category = $('body').data('event-category');
    } else {
      category = this._categoryGeneral;
    }
    if (elm.data('event-action')) {
      action = elm.data('event-action');
    } else if (elm.parent().data('event-action')) {
      action = elm.parent().data('event-action');
    } else {
      action = $('body').data('event-action');
    }
    this.sendEventCustom(category, action, label)
  }

  sendEventCustom(category: string, action: string, label: string): void {
    // if (Tools.isMobile()) {
    //   category += "_movil";
    // }
    ga('send', 'event', category, action, label);
  }

  sendPage(url: string): void {
    ga('send', 'pageview', url);
  }
}

class Site{
  index(){
    var isMobile = window.matchMedia('(max-width: 640px)');
    var $mapData = $('.js-map-data');

    if (isMobile.matches) {
      if ($mapData.length) {
        if ($mapData.outerHeight() >= 627) {
          $mapData.css({
            'overflow': 'auto',
            'height': '640px'
          });
        }
      }
    }

    var cur = 0;
    var count = $('.section-services__info-slider-item').length;

    $('.section-services__info-slider-item').hide();
    $('.section-services__info-slider-item').eq(0).show();

    setInterval(function() {
        $('.section-services__info-slider-item').eq(cur).fadeOut(() => {
            $(this).removeClass('active');
            cur = (cur + 1) % count;
            $('.section-services__info-slider-item').eq(cur).addClass('active').fadeIn();
        });
    }, 3000);

    var search_params = {
      sends: false,
      payments: false,
      exchange: false
    };

    $('.content .section-services__search-selectors-button').on('click', function (e) {
      if ($(e.target).hasClass('clicked')) {
        $(e.target).removeClass('clicked');
      } else {
        $(e.target).addClass('clicked');
        $('.message-search').remove();
      }

      search_params[$(e.target).attr('data-search-param')] = !search_params[$(e.target).attr('data-search-param')];
    });

    this.ubigeoActions(search_params);

    let $buttonService = $('.section-services__search-selectors-button');

    $('.section-services__search-form-button').on('click', (e)=>{
      if ($buttonService.hasClass('clicked')) {

        var slct = $('form#send').find('select');
        var slctLength = slct.length;

        $('.message-search').remove();

        var selectedUbigeos = 0;

        for (var i = 0; i < slctLength; ++i) {
          if (!(typeof $(slct[i]).find(':selected').attr('value') == typeof undefined)) {
            selectedUbigeos += 1;
          }
        }

        if (selectedUbigeos > 0) {
          $.ajax({
            type: "POST",
            url: "/stores",
            data: search_params,
            success: (data) => {
              let $sectionMap = $('.section-search.invisible');
              $sectionMap.slideDown('slow', () => { });
              this.loadMap(data);
            },
            error: () => {
              this.loadMap(null);
            }
          });
        } else {
          $('form#send').after('<span class="message-search">Para ser más precisos en la búsqueda use los filtros</span>');
        }
      } else {
        $buttonService.removeClass('run-feedback');
        setTimeout(() => {
          $buttonService.addClass('run-feedback');
        }, 100);
        $('form#send').after('<span class="message-search">Debe seleccionar al menos un servicio</span>');
      }
    });
  }

  emptyValidate(el) {
    if ($(el).val() != '')
      $(el).addClass('noempty');
    else
      $(el).removeClass('noempty');
  }

  loadUbigeo(data, title, element) {
    $(element).prop( "disabled", false );
    var div = '<option selected="selected">' + title + '</option>';
    for (var idx in data) {
           div += '<option value="' + data[idx].id + '" >' + data[idx].name + '</option>';
    }
    $(element).html(div);
  };

  ubigeoActions(search_params) {
    $('#department').on('change', (e)=>{
        this.emptyValidate(e.currentTarget);

        $.ajax({
        type: "GET",
        url: "/" + $(e.currentTarget).val() + "/ubigeos" ,
        success: (data) => {
          if (data && data.length) {
              this.loadUbigeo(data, "provincia", "#province");
          }
        }
      });
      search_params.ubigeo = $(e.currentTarget).val();
    });

    $('#province').on('change', (e)=>{
      this.emptyValidate(e.currentTarget);

      $.ajax({
        type: "GET",
        url: "/" + $(e.currentTarget).val() + "/ubigeos" ,
        success: (data) => {
          if (data && data.length) {
            this.loadUbigeo(data, "distrito", "#district");
          }
        }
      });
      search_params.ubigeo = $(e.currentTarget).val();
    });

    $('#district').on('change', (e)=>{
        this.emptyValidate(e.currentTarget);
        search_params.ubigeo = $(e.currentTarget).val();
    });
  }

  loadStoreList(data) {
    $('.section-search__map-google-data-content.js-map-data ul li').remove();

    var stores = "";

    for (var i = 0; i < data.length; i++) {
      stores += "<li data-id=\"" + data[i].id + "\" " +
                "data-lat=\"" + data[i].lat +
                "\" data-lon=\"" + data[i].lon + "\">" +
                "<span class=\"name\">" + data[i].name + "</span>" +
                "<span class=\"address\">" + data[i].address + "</span>" +
                "</li>";
    }

    $('.section-search__map-google-data-content.js-map-data ul').append(stores);
    $('.section-search__map-google-data-content.js-map-data ul li').on('click', (evt) => {
      $('.section-search__map-google-data-content.js-map-data ul li').removeClass("highlight");
      $(evt.currentTarget).addClass("highlight");
      var id = $(evt.currentTarget).data("id");
      var lat = $(evt.currentTarget).data("lat");
      var lon = $(evt.currentTarget).data("lon");
      Maps.panTo(id, lat, lon);
    });
  }

  loadMap(storeList) {
    // Action
    var data = [];

    if (storeList && (storeList.length >= 0)) {
      for (var i = 0; i < storeList.length; i++) {
        data.push({ id: storeList[i].id, type: storeList[i].type, position: new google.maps.LatLng(storeList[i].lat, storeList[i].lon)});
      }
    } else {
      for (var i = 0; i < 10; i++) {
        data.push({ position: new google.maps.LatLng(-12.00 + (Math.random() - 0.5) * 0.02, -77.10 + (Math.random() - 0.5) * 0.02)});
      }
    }

    //initMap()
    this.loadStoreList(storeList);
    Maps.loadMarks(data);
  }

  calculator(){
  }

  complaint() {
    if ($(window).width() <= 1076) {
      $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: true,
        responsive: {
          320: {
            items: 2
          },
          600: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      })
    }
  }

  sends(){
    var search_params = { sends: true };
    this.ubigeoActions(search_params);
    var _this = this;

    $('.section-search-horizontal__form-button').on('click', function (e) {
      var slct = $('form#find-horizontal').find('select');
      var slctLength = slct.length;
      $('.message-search-black').remove();
      var selectedUbigeos = 0;
      for (var i = 0; i < slctLength; ++i) {
          if (!(typeof $(slct[i]).find(':selected').attr('value') == typeof undefined)) {
              selectedUbigeos += 1;
          }
      }
      if (selectedUbigeos > 0) {
          $.ajax({
              type: "POST",
              url: "/stores",
              data: search_params,
              success: function (data) {
                  _this.loadMap(data);
              },
              error: function () {
                  _this.loadMap(null);
              }
          });
      }
      else {
          $('form#find-horizontal').after('<span class="message-search-black">Para ser más precisos en la búsqueda use los filtros</span>');
      }
    });
  }

  payment(){

    var $billersCarousel = $('.js-billers-carousel');

    $billersCarousel.owlCarousel({
        // items: 5,
        margin: 10,
        loop: true,
        // dots: false,
        nav: true,
        slideBy: 3,
        navText: ['', ''],
        responsive: {
            0: {
                items: 2
            },
            480: {
                items: 3
            },
            768: {
                items: 4
            },
            992: {
                items: 5
            }
        }
    });

    var search_params = { payments: true };
    this.ubigeoActions(search_params);
    var _this = this;

    $('.section-search-horizontal__form-button').on('click', function (e) {
      var slct = $('form#find-horizontal').find('select');
      var slctLength = slct.length;
      $('.message-search-black').remove();
      var selectedUbigeos = 0;
      for (var i = 0; i < slctLength; ++i) {
          if (!(typeof $(slct[i]).find(':selected').attr('value') == typeof undefined)) {
              selectedUbigeos += 1;
          }
      }
      if (selectedUbigeos > 0) {
          $.ajax({
              type: "POST",
              url: "/stores",
              data: search_params,
              success: function (data) {
                  _this.loadMap(data);
              },
              error: function () {
                  _this.loadMap(null);
              }
          });
      }
      else {
          $('form#find-horizontal').after('<span class="message-search-black">Para ser más precisos en la búsqueda use los filtros</span>');
      }
    });
  }

  exchange(){
    var search_params = { exchange: true };
    this.ubigeoActions(search_params);
    var _this = this;

    $('.section-search-horizontal__form-button').on('click', function (e) {
      var slct = $('form#find').find('select');
      var slctLength = slct.length;
      $('.message-search-black').remove();
      var selectedUbigeos = 0;
      for (var i = 0; i < slctLength; ++i) {
          if (!(typeof $(slct[i]).find(':selected').attr('value') == typeof undefined)) {
              selectedUbigeos += 1;
          }
      }
      if (selectedUbigeos > 0) {
          $.ajax({
              type: "POST",
              url: "/stores",
              data: search_params,
              success: function (data) {
                  _this.loadMap(data);
              },
              error: function () {
                  _this.loadMap(null);
              }
          });
      }
      else {
          $('form#find').after('<span class="message-search-black">Para ser más precisos en la búsqueda use los filtros</span>');
      }
    });
  }

  venezuela(){
    new Accordion().setAccordion();
  }

  faqs(){
    new Accordion().setAccordion();
  }
}
class Maps{
  map;
  static setMap(idMap){
    this.map = Mapster.create(document.getElementById(idMap));
    //this.map.gmap.setZoom(10);
  }
  static panTo(id, lat, lon) {
    this.map.panTo(id, new google.maps.LatLng(lat, lon));
  }
  static loadMarks(data){
    this.map.removeAllMarkers();

    //Extend markerBounds with each random point.
    var bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < data.length; i++) {
      var position = data[i].position;

      bounds.extend(position);

      var markerIcon = '../images/marker-map.png';

      if (data[i].type == "Agencia Principal") {
        markerIcon = '../images/marker-map-black.png';
      }

      this.map.addMarker({
        position: position,
        visible: true,
        draggable: false,
        id: data[i].id,
        icon: markerIcon,
        event: {
          name: 'click',
          callback: function(e) {
            //console.log(e.latLng.lat());
            //console.log(e.latLng.lng());
          }
        }
      });
    }

    //Method to set the map to fit
    this.map.gmap.fitBounds(bounds);
  }
}
class Accordion{
  setAccordion() {
    var btnExpand = document.getElementsByClassName("section-faqs__accordion-header-right-expand")[0];
    var btnCollapse = document.getElementsByClassName("section-faqs__accordion-header-right-collapse")[0];

    this.doAction(this.toggleButton);

    btnExpand.onclick = (evt)=> {
      this.doAction(this.expandAll);
    }
    btnCollapse.onclick = (evt)=> {
      this.doAction(this.collapseAll);
    }
  }

  toggleButton(item){
    item.onclick = (evt)=> {
      item.classList.toggle("active");
      var panel = item.nextElementSibling;
      panel.style.maxHeight = (panel.style.maxHeight)?null:panel.scrollHeight + "px";
    }
  }

  expandAll(item){
    item.classList.add("active");
    var panel = item.nextElementSibling;
    panel.style.maxHeight = panel.scrollHeight + "px";
  }

  collapseAll(item){
    item.classList.remove("active");
    var panel = item.nextElementSibling;
    panel.style.maxHeight = null;
  }

  doAction(action){
    var buttons = document.getElementsByClassName("section-faqs__accordion-items-button");
    for (var i = 0; i < buttons.length; i++) {
      action(buttons[i]);
    }
  }
}
class CommonController {
  constructor() {
  }

  allActions() {
    $('.js-collapserNav').on('click', (evt) => {
      evt.preventDefault();
      $('.user-header').toggleClass('show-menu-mobile');
    });

    $('.menu li').on('click', (evt) => {
      $('.menu li').removeClass('open');
      $(evt.currentTarget).addClass('open');
    });
  }
}

var Public = {
  initiatedClasses: {}
}

var UTIL = {
  exec: function (controller, action = 'allActions') {
    if (window[controller]) {
      if (Public.initiatedClasses[controller]) {
        var controllerClass = Public.initiatedClasses[controller];
      } else {
        var controllerClass = Object.create((<any>window[controller]).prototype);
        controllerClass.constructor.apply(controllerClass);
        Public.initiatedClasses[controller] = controllerClass;
      }
      if (controllerClass[action]) {
        controllerClass[action]();
      }
    }
  },
  init: function () {
    var body = $("body");
    var controller = body.data("router-class");
    var action = body.data("router-action");

    this.exec("CommonController");
    this.exec(controller);
    this.exec(controller, action);
  }
}

$(document).on('ready', function () {
  UTIL.init();
});
